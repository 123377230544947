import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import * as _ from 'underscore';
import { SubscribeDialogComponent } from 'app/shared/subscribe-dialog/subscribe-dialog.component';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import { takeUntil } from 'rxjs/operators';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'app/shared/auth.service';
import { RestService } from 'app/shared/rest.service';
import { VerifyAddressComponent } from 'app/shared/verify-address/verify-address.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDrawerMode } from '@angular/material/sidenav';
import { CommonService } from 'app/shared/common.service';
import { RgetRegexService } from 'app/shared/getRegex.service';
import { ShippingWebAliasCheckDialogComponent } from '../Shipping/modals/ShippingWebAliasCheckDialog/ShippingWebAliasCheckDialog.component';
import { SuperAminoLimitItem } from 'app/constants';
@Component({
    selector: 'summary-cart-button',
    templateUrl: './summary-cart-button.component.html',
    styleUrls: ['./summary-cart-button.component.scss'],
    animations: [
        trigger('slideInOut', [
          state('in', style({ transform: 'translateX(0)' })),
          state('out', style({ transform: 'translateX(-100%)' })),
          transition('in => out', animate('300ms ease-out')),
          transition('out => in', animate('300ms ease-in')),
        ]),
      ]
})
export class SummaryCartComponentButton implements OnInit, OnDestroy {
    @Input() routPath: any;
    @Input() customerInfoForm: any;
    @Input() shippingForm: any;
    @Input() shippingMethodForm: any;
    @Input() referredForm: any;
    @Input() billingAddressForm: any;
    @Input() paymentTypeForm: any;
    @Input() subscribeDialogShow: any;
    @Input() accountInfoForm: any;

    dialogRef: any;
    currentRouterPath: any;
    finalShippingMethod: any;
    siteType: any;
    customerDetail: any = '';
    priceType: any ;
    backofficeUrl: any;
    packSectionTitle: any;
    public isLogout: boolean = false;
    public selectedDiscount: any;
    public DiscountValue:any;
    isSelected:boolean = false;
    public totalRemainingBalance:any;
    private _unsubscribeAll: Subject<any>;
    mode = new FormControl('over' as MatDrawerMode);
    ProductCode: any;
    SmartOrderDate: any;
    LoggedInUser:any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    SubscriptionProcessType: any;
    invalidzip1:any;
    invalidzip2:any;
    invalidzip3:any;
    paymentDetails:any;
    creditCardNumberDisplayField:any;
    constructor(
        private _toastr: ToastrService,
        private _matDialog: MatDialog,
        private _restService: RestService,
        private router: Router,
        public _enrollSession: EnrollSessionService,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        public _sharedMethodService: SharedMethodService,
        public _calculateRetailOrderService: CalculateRetailOrderService,
        private ref: ChangeDetectorRef,
        private cookieService: CookieService,
        private _auth: AuthService,
        private _activeRoute: ActivatedRoute,
        public _commonService: CommonService,
        public _RgetRegexService: RgetRegexService

    ) {
        this.ProductCode = this._activeRoute.snapshot.paramMap.get('id');
        this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {}
        this.currentRouterPath = this.router.url.split('?')[0];
        if (this._enrollSession.data.RetailOrderSummary == null && this.currentRouterPath != '/AllProducts/' + this.ProductCode && this.CollectionRoute() && this.currentRouterPath != '/homepage' && this.currentRouterPath != '/documents/privacy' && this.currentRouterPath != '/documents/shipping' && this.currentRouterPath != '/documents/support' && this.currentRouterPath != '/Calendar' && this.currentRouterPath != '/documents/incentives' && this.currentRouterPath != '/Ultlifestyle' && this.currentRouterPath != '/ResetShopifyPassword') {
            this.router.navigate(['/AllProducts']);
        }
        this._unsubscribeAll = new Subject();
        this.finalShippingMethod = this.finalShippingMethod || 0;
        this.siteType = sessionStorage.getItem("siteType");
        this.creditCardNumberDisplayField = this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField ? this.paymentDetails?.primaryCreditCardField.creditCardNumberDisplayField : null;
    }

    ngOnInit(): void  {
        this._calculateRetailOrderService.cartSubtotal = 0;
        var cartSubTotal = 0;
        _.each(this._enrollSession.data?.RetailOrderSummary?.InitialOrders, function (item) {
            if (item.ItemCode !== "7800GC") {
                cartSubTotal += parseFloat(item.RetailPrice) * parseInt(item.Quantity, 10);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary?.Enrollpack, function (item) {
            if (item.ItemCode !== "7800GC") {
                cartSubTotal += parseFloat(item.RetailPrice) * parseInt(item.Quantity, 10);
            }
        });
        this._calculateRetailOrderService.cartSubtotal = cartSubTotal;
        this.currentRouterPath = this.router.url.split('?')[0];
        this._sharedMethodService.checkSubTotal.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res) {
                if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }
            }
        })
        if (this._auth.isLoggedIn()) {
            this.isLogout = true;
            if (this.customerDetail=='') {
                setTimeout(() => {
                    this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
                    this.priceType = this._enrollSession.data.priceType;
                }, 2000);   
            }
        }
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
        this.priceType = this._enrollSession.data.priceType;

        if (this.siteType == 'fruitsandveggie') {
            this.packSectionTitle = 'Fruit & Veggie+ Pack';
        }
        else if (this.siteType == 'collagen') {
            this.packSectionTitle = 'Collagen Pack';
        }
        else {
            this.packSectionTitle = 'Transformation Pack';
        }
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    CollectionRoute(){
        if(this.currentRouterPath != '/Collection1' && this.currentRouterPath != '/Collection2' && this.currentRouterPath != '/Collection3' && this.currentRouterPath != '/Collection4' && this.currentRouterPath != '/Collection5' && this.currentRouterPath != '/Collection7' && this.currentRouterPath != '/IRT'){
            return true;
        }
        else{
            return false
        }
    }
    subscribeDialog() {
        if ((this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/"+this.ProductCode)  && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
            if(window.innerWidth < 550){
                this.closeMobileSummaryCart();
              }
              else{
                  this._sharedMethodService.toggleSidenavforaddtocart();
              }
            this.onClickChange();
        }
        else if (this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/"+this.ProductCode) {
            this.dialogRef = this._matDialog.open(SubscribeDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '375px',
                panelClass: '',
                disableClose: true
            });
            this.dialogRef.afterClosed()
                .subscribe(response => {
                    if (!response) {
                        return;
                    } else {
                        if (response == 'false') {
                            if(window.innerWidth < 550){
                                this.closeMobileSummaryCart();
                              }
                              else{
                                  this._sharedMethodService.toggleSidenavforaddtocart();
                              }
                            this.onClickChange();
                            this.router.navigate(['/Shipping']);
                        }
                        if (response == 'true') {
                           return
                        }
                    }

                });

        }
    }

    onClickChange(isCheckout?: boolean) {

        if (window.innerWidth < 550 && (this.currentRouterPath != "/Subscribe" && this.currentRouterPath != "/Subscribe/" + this.ProductCode)) {
            this.closeMobileSummaryCart();
        }
        if (this.referredForm || this.customerInfoForm || this.shippingForm || this.shippingMethodForm) {
            if (this.shippingForm && this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 == undefined) {
                this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 = "";
            }
            if ((!this._auth.isLoggedIn() && this.referredForm.invalid) ||  this.customerInfoForm.invalid || this.shippingForm.invalid || this.shippingMethodForm.invalid) {
                if (!this._auth.isLoggedIn() && this.referredForm.invalid) {
                    this.validateAllFormFields(this.referredForm);
                    this._toastr.error('Please fill the referral code', 'error');
                    return;
                }
                if (this.customerInfoForm.invalid) {
                    this.validateAllFormFields(this.customerInfoForm);
                    this._toastr.error('Please fill all the customer info.', 'error');
                }
                if (this.shippingForm.invalid) {
                    this.validateAllFormFields(this.shippingForm);
                    this._toastr.error('Please fill all the shipping info.', 'error');
                }
                if (this.shippingMethodForm.invalid) {
                    this.validateAllFormFields(this.shippingMethodForm);
                    this._toastr.error('Please fill shipping method and secure shipping option.', 'error');
                }
                return;
            }
        }

        if (this.billingAddressForm || this.paymentTypeForm) {
            if (this.billingAddressForm && this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 == undefined) {
                this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 = "";
            }
            if (this.billingAddressForm.invalid || this.paymentTypeForm.invalid) {
               
                if (this.billingAddressForm.invalid) {
                    this.validateAllFormFields(this.billingAddressForm);
                    this._toastr.error('Please fill all required fields', 'error');
                    return;
                }
                if (this._auth.isLoggedIn() ? (this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "true" ? this.paymentTypeForm.valid : this.paymentTypeForm.invalid) : this.paymentTypeForm.invalid) {
                    this.validateAllFormFields(this.paymentTypeForm);
                    this._toastr.error('Please fill all required fields', 'error');
                    return;
                }
                if(this._auth.isLoggedIn()){
                    if(this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "true" && (this.creditCardNumberDisplayField === null || this.creditCardNumberDisplayField === undefined || this.creditCardNumberDisplayField === '')){
                        this._toastr.error('Please fill all required fields', 'error');
                        return;
                    }
                }
            }
        }
        if(this.currentRouterPath.includes("Shipping")){
            this.regexValidateZip(this._enrollSession.data.RetailOrderSummary?.customerInfoData?.ZipCode);
            if(this.invalidzip1){
                return this._toastr.error('Please check your zipcode.','error');
            }
            this.regexValidateZip2(this._enrollSession.data.RetailOrderSummary?.shippingFormData?.ZipCode);
            if(this.invalidzip2){
                return this._toastr.error('Please check your zipcode.','error');
            }
        }
        if(this.currentRouterPath.includes("Checkout")){
            this.regexValidateZip3(this._enrollSession.data.RetailOrderSummary?.billingAddressData?.ZipCode);
            if(this.invalidzip3){
                return this._toastr.error('Please check your zipcode.','error');
            }
        }
        if (this.currentRouterPath.includes("AllProducts") && (this._enrollSession.data.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary.InitialOrders == undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0) && (!this.siteType || this.siteType == null || this.siteType == '')) {
            this._toastr.warning("Please select an item", '');
            return;
        }
        if (this.siteType!='ultlifestyle' && this.currentRouterPath.includes("Subscribe") && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && (this._enrollSession.data.smartOrderDate == undefined || this._enrollSession.data.smartOrderDate == null || this._enrollSession.data.smartOrderDate == '')) {
            
            this._toastr.error('Please select Smart Order Date', 'error');
            const firstElementWithError  = document.querySelector('.dateValidation') as HTMLElement;
                if (firstElementWithError) {
                const scrollPositionAbove = firstElementWithError.offsetTop - 100; // Adjust the value as needed
                window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
            }
            return;
        }
        else
        if(this.siteType!='ultlifestyle' && this.currentRouterPath == "/Subscribe"){
            this.router.navigate(['/Shipping']);
        }

        if (this._commonService.isLoginCartButton() && (this.currentRouterPath.includes("AllProducts") || this.currentRouterPath.includes("Collection") )) {
            this.router.navigate(['/Shipping']);
        }
        if (this.routPath == 'Shipping' && this.currentRouterPath.includes("Subscribe") && this.siteType.includes("ultpack")) {
            return;
        }
        if (this.routPath == 'Checkout' && this.currentRouterPath.includes("Shipping") && this.accountInfoForm.value.Password !== "" && !this._auth.isLoggedIn()){
            this._commonService.validatePassword(this.accountInfoForm.value.Password);
            if(this.accountInfoForm.value.Password !== this.accountInfoForm.value.ConfirmPassword){
                this._toastr.error('Please verify your Password', 'error');
                return;
            }
            if (this._commonService.hasLower == false || this._commonService.hasLength == false || this._commonService.hasUpper == false || this._commonService.hasNum == false || this._commonService.hasSpecial == false) {
                this._toastr.error('Please check your Password', 'error');
                return;
            }
        }
        if(this.currentRouterPath.includes("Checkout") && this.paymentTypeForm.value.cardNumber !== "" && this.paymentTypeForm.value.cardNumber !== null){
            this._commonService.validateCardNumber(this.paymentTypeForm.value.cardNumber);
            if(this._commonService.hasCardNumberCheck === false){
                this._toastr.error('Please check your Card Number','error');
                return;
            }
        }
        if (this.currentRouterPath.includes("Shipping") && !this._auth.isLoggedIn() && (this._enrollSession.data.CustomerID === null || this._enrollSession.data.CustomerID === '' || this._enrollSession.data.CustomerID === undefined)){
            this._commonService.createCustomer(18);
        }
        if (this.currentRouterPath.includes("Shipping") && !this._auth.isLoggedIn() && this._enrollSession.data.RetailOrderSummary.customerInfoData.Email && ((this._enrollSession.data.CustomerID === null || this._enrollSession.data.CustomerID === '' || this._enrollSession.data.CustomerID === undefined) || (this._enrollSession.data.customertype == 11 || this._enrollSession.data.customertype == 18))) {
                const mailrequest = '?email=' + this._enrollSession.data.RetailOrderSummary.customerInfoData.Email;
                this._restService.IsEmailRegistered(mailrequest).subscribe((result: any) => {
                    if (!result) {
                        this._toastr.error('Email Already Registered', 'Error');
                        this.customerInfoForm.get("Email").reset();
                        return;
                    } else {
                        if (this.routPath != "" && this.routPath != null && (this.routPath != "Shipping" || this.siteType == 'ultlifestyle' || this.siteType == 'ultpack')) {
                            const allOrders = [
                                ...(this._enrollSession.data.RetailOrderSummary?.InitialOrders ?? []),
                                ...(this._enrollSession.data.RetailOrderSummary?.AddOnOrders ?? []),
                                ...(this._enrollSession.data.RetailOrderSummary?.Enrollpack ?? [])
                                ];
                                    
                            const SuperAminoItemDetail = _.find(allOrders, (item: any) => 
                            item.ItemCode == SuperAminoLimitItem.itemCode
                            );
                            if (SuperAminoItemDetail && this.routPath == 'Checkout' && this.currentRouterPath.includes("Shipping") && this._enrollSession.data.RetailOrderSummary.shippingFormData) {
                               
                                let reqAddress = {
                                    Address1: this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
                                    City: this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
                                    State: this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
                                    Zip: this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode
                                }
                                this._restService.GetSuperAminoAddressCheck(reqAddress).subscribe((response: any) => {
                                    if (response && response.length > 0) {
                                        sessionStorage.setItem("SuperAminoLimit", response[0].qty_allowed);
                                        const superAminoLimit = Number(response[0].qty_allowed) || 2;
                                        const isOverLimit = SuperAminoItemDetail
                                        ? (SuperAminoItemDetail.Quantity) > superAminoLimit
                                        : false;
                                        
                                        if (isOverLimit) {
                                            this._toastr.info(
                                                `You have to remove or decrease the quantity of ${SuperAminoLimitItem.name}.`
                                            );
                                            return;
                                        }
                                        else{
                                            this.addressFunctionality();
                                        }
                                    } else {
                                        this.addressFunctionality();
                                    };
                                });
                            }
                            else
                            {
                                this.addressFunctionality();
                            }
                        }
                    }
                });
        }
        else {
            if (this.routPath != "" && this.routPath != null && (this.siteType === null || this.siteType == "" || this.siteType == 'ultlifestyle' || this.siteType == 'ultpack')) {
                if (this.routPath == 'Checkout' && this.currentRouterPath.includes("Shipping") && this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode == 'US') {
                    this.suggestedAddress();
                    return ;
                }
                else { this.router.navigate(['/', this.routPath]); }
            }
        }
    }
    
    addressFunctionality()
    {
        if (this.routPath == 'Checkout' && this.currentRouterPath.includes("Shipping") && this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode == 'US') {
            this.suggestedAddress();
            return ;
        }
        if (this.routPath == 'Checkout' && this.currentRouterPath.includes("Shipping") && this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode == 'CA') {
            this.customExistingCustomerFunctionality();
            return;
        }
        else { this.router.navigate(['/', this.routPath]); }
    }

    regexValidateZip(zipCode): void {
        const customerInfoForm = this.customerInfoForm ? this.customerInfoForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(customerInfoForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(zipCode)) {
            this.invalidzip1 = false;
        } else if (zipCode && (zipCode.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }
    regexValidateZip2(zipCode): void {
        const shippingForm = this.shippingForm ? this.shippingForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(zipCode)) {
            this.invalidzip2 = false;
        } else if (zipCode && (zipCode.indexOf(' ') !== 0)) {
            this.invalidzip2 = true;
        }
        else {
            this.invalidzip2 = false;
        }
    }
    regexValidateZip3(zipCode): void {
        const billingAddressForm = this.billingAddressForm ? this.billingAddressForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(billingAddressForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(zipCode)) {
            this.invalidzip3 = false;
        } else if (zipCode && (zipCode.indexOf(' ') !== 0)) {
            this.invalidzip3 = true;
        }
        else {
            this.invalidzip3 = false;
        }
    }
    customExistingCustomerFunctionality() {
        this._restService.GetCustomersByEmail(this._enrollSession.data.RetailOrderSummary.customerInfoData.Email).subscribe((result: any) => {
            if (result.Customer && result.Customer.customerIDField > 0 && (result.Customer.customerTypeField == 18 || result.Customer.customerTypeField == 11)) {
                this._enrollSession.data.CustomerID = result.Customer.customerIDField;
                this._enrollSession.data.customertype = result.Customer.customerTypeField;
                sessionStorage.setItem('CustomExistingCustomerType18&11', JSON.stringify(result));
                let enrollerId = result.Customer.enrollerIDField;
                if (enrollerId > 0 && this._enrollSession.data.RetailEnroll.EnrollerId > 0 && enrollerId != this._enrollSession.data.RetailEnroll.EnrollerId) {
                    const dialogRef = this._matDialog.open(ShippingWebAliasCheckDialogComponent, {
                        width: '500px',
                        maxWidth: '500px',
                        height: '400px',
                        panelClass: '',
                        disableClose: true,
                        data: {
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        this.router.navigate(['/', this.routPath]);
                    });
                }
                else{
                    this.router.navigate(['/', this.routPath]);
                }
            }
            else{
                this.router.navigate(['/', this.routPath]);
            }
        })
    }
    removedOrder(index, Quantity, parentId, orderType) {
        if (orderType == 'todayOrder') {
            this.removeInitOrder({ index: index, Quantity: Quantity, parentId: parentId });
        }
        if (orderType == 'smartOrder') {
            this.removeSmartOrder({ index: index, Quantity: Quantity, parentId: parentId });
        }
        if (orderType == 'transformation') {
            this.EditTransformPack();
        }
        if ((this._enrollSession.data.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary.InitialOrders == undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0) &&  (!this.siteType || this.siteType==null||this.siteType=='')) {
            this._toastr.warning("Please select an item", '');
            this.router.navigate(['/AllProducts']);
        }
    }
    // ****removeSmartOrder****/
    removeSmartOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');

        this._enrollSession.data.SmartOrdersSubTotal = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
        });
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = '';
        }
        this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
    }
    // ****removeInitOrder****/
    removeInitOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
            }
        }
        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);
        });
        this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId && item.ParentId !== undefined;
        });

        this._enrollSession.data.InitialOrdersSubItems = '';
        this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = '';
        }
        this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
                
        const firstElementWithError = document.querySelector('.ng-invalid') as HTMLElement;
        if (firstElementWithError) {
            const scrollPositionAbove = firstElementWithError.offsetTop - 200; // Adjust the value as needed
            window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
        }
    }
    EditTransformPack() {
        this._enrollSession.editTransformationPack = "true";
        this.router.navigate(['/AllProducts']);
    }
    closeMobileSummaryCart() {
        this._enrollSession.SummaryCartMobOnly = !this._enrollSession.SummaryCartMobOnly;
    }
    currentPage()
    {
        return window.innerWidth < 550 && this.currentRouterPath != '/homepage' && this.currentRouterPath != '/Confirmation' && this.currentRouterPath != '/Ultlifestyle' && this.currentRouterPath != '/ResetShopifyPassword'
    }
    
    logout() {
      this.cookieService.delete('access_token', '/');
      this.cookieService.delete('X-Auth', '/');
      this.cookieService.deleteAll('/');
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.IsLoggedIn=false;
      this._enrollSession.data.LoggedInUserData={};
        this.router.navigate(['/AllProducts']).then(() => {
          if (window.localStorage && window.sessionStorage) {
            window.localStorage.removeItem('user');
            window.localStorage.clear();
            window.sessionStorage.clear();
          }
          window.location.reload();
        });

        localStorage.setItem("isExistingAutoship","false");
    }
    ngDoCheck() {
        this.ref.markForCheck();
    }
    redirectToBackoffice(){
        let windowReference = window.open();
        this._restService.SilentLogin(this._enrollSession.data.LoggedInUserData.CustomerID).subscribe((res:any) => {
            this.backofficeUrl=res;
            windowReference.location = res;
          }, error => {
            console.log("In error")
          })
    }
    suggestedAddress()
    {
        let reqAddress = {
            address1 : this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
            city : this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
            state: this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
            countryCode: this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,
            zip: this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode
          }

        
        this._restService.GetVerifedAddressResponseV1(reqAddress).subscribe((response : any)=>
        {
            if (!response.Data.isVerified) {
                this.dialogRef = this._matDialog.open(VerifyAddressComponent, {
                    width: '550px',
                    maxWidth: '550px',
                    height: '456px',
                    panelClass: '',
                    data: {
                        suggestedAddress: {},
                        userAddress: reqAddress,
                        InvalidAddress: true
                    },
                    disableClose: true
                });
                this.dialogRef.afterClosed()
                    .subscribe(response => {
                        if (response.continue) {
                            this.customExistingCustomerFunctionality();
                            //this.router.navigate(['/', this.routPath]);
                        }
                        else { return; }
                    });
            }
            else{
                let resAddress = {
                    address1: response.Data.response && response.Data.response.addressField !== null ? (response.Data.response.addressField).trim() : '',
                    city: response.Data.response.cityField,
                    state: response.Data.response.stateField,
                    countryCode: response.Data.response.countryField,
                    zip: response.Data.response.zipField
                }
                let isSuggestedAddress = !this._commonService.checkAddress(reqAddress,resAddress)
                if (isSuggestedAddress) {
                    this.dialogRef = this._matDialog.open(VerifyAddressComponent, {
                        width: '550px',
                        maxWidth: '550px',
                        height: '456px',
                        panelClass: '',
                        data: {
                            suggestedAddress : resAddress,
                            userAddress: reqAddress,
                            InvalidAddress: false
                        },
                        disableClose: true
                    });
                    this.dialogRef.afterClosed()
                        .subscribe(response => {
                            if (response.continue) {
                                this.router.navigate(['/', this.routPath]);
                            }
                            else{
                                return;
                            }
                        });
        
                }
                else
                {
                    this.router.navigate(['/', this.routPath]);
                }
            }
        })
    }
    getItemPrice(product) {

        var item = _.filter(this._enrollSession.data.RetailOrderProductDetail, (item) => {
            return (item.ParentItemCode == product.ItemCode || !item.ParentItemCode) && (item.ItemCode == product.ItemCode || item.ItemCode == (product.ItemCode + '-P65'));
        });
        return item.length > 0 ? item[0].PriceEach : 0;
    }
}
