import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { API_URL, objectToParams } from '../constants';
import { RestService } from '../shared/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { _ROUTER_CONFIG } from '@ngrx/router-store/src/router_store_module';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import * as moment from 'moment';
import * as _ from 'underscore';
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    customerLoginForm: FormGroup;
    routPath: any = "Subscribe";
    CustomerID: any;
    AutoOrderID: any;
    username: any;
    userCustomerId: any;
    ssoToken: any;
    adminToken: any;
    public userInfo: any = {};
    public loginDetails: any = {};
    cookieDomainUrl: any;
    siteType: any;
    SubscriptionProcessType: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _restService: RestService,
        private _router: Router,
        private _toastr: ToastrService,
        private _auth: AuthService,
        private _activeRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private cookieService: CookieService,
        public _enrollSession: EnrollSessionService,
        public _sharedMethodService: SharedMethodService,
    ) {
        let params = new URLSearchParams(location.search);
        this.CustomerID = params.get('CustomerID') || "";
        this.AutoOrderID = params.get('AutoOrderID') || "";
        localStorage.removeItem('SmartOrderItems');
        localStorage.removeItem('SmartOrderDetails');
        localStorage.removeItem('SmartOrderBVTotal');
        localStorage.removeItem('SmartOrdersSubTotal');
        localStorage.removeItem('paymentInfo');
        localStorage.removeItem('billingAddress');
        localStorage.removeItem('shippingAddress');
        localStorage.removeItem('SmartOrderSubItems');
        localStorage.removeItem('AutoShipStartDate');
        localStorage.removeItem('CustomShipMethods');
        localStorage.removeItem('orderDetails');
        localStorage.removeItem('customerDetail');
        localStorage.removeItem('CustomShipMethods');
        localStorage.removeItem('paymentCardType');
        localStorage.removeItem('City');
        localStorage.removeItem('State');
        localStorage.removeItem('Zip');
        localStorage.removeItem('CountryCode');
        localStorage.removeItem('DefaultCountryCode');
        this.customerLoginForm = this.createCustomerLoginForm();
        this.siteType = window.location.pathname;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.cookieDomainUrl = '.puriumenrollment.com';
        
        if (location.host.indexOf('wsicloud.net') > -1) {
            this.cookieDomainUrl = '.wsicloud.net';
        }
        this._activeRoute.params.subscribe((routeParams: any) => {
            const params = { ...this._activeRoute.snapshot.queryParams };
            this.userCustomerId = params.CustomerId;
            this.username = params.Username;
            this.ssoToken = params.SSOToken;
            this.adminToken = params.token;
            delete params.Username;
            delete params.CustomerId;
            delete params.SSOToken;
            this._router.navigate([], { queryParams: params });
        });
        let auth = this.getCookie('Authtoken');
        if (this.ssoToken) {
            this.ssoToken = this.ssoToken.replace(/\s/g, "+");
            localStorage.setItem("authtoken", null);
            this.onLogin(this.ssoToken, "SSO", true);
        }
        else if (this.adminToken) {
            this.adminToken = this.adminToken.replace(/\s/g, "+");
            localStorage.setItem("authtoken", null);
            this.onLogin(this.adminToken, "AdminSSO", true);
        }
        else if (this.userCustomerId && this.username) {
            localStorage.setItem("authtoken", null);
            this.onLogin(this.userCustomerId, this.username, false);
        }
        else if (auth) {
            let _token = { access_token: auth }
            this._auth.sendToken(_token);
            this.GetCustomersDetails(0);
        }
        else {

        }
    }

    GetCustomersDetails(customerId) {
        const self = this;
        let req = "?CustomerID=" + (customerId ? customerId : 0);
        self._restService.GetCustomersDetails(req).subscribe(
            (res: any) => {
                this._enrollSession.data.priceType = res.customerDetail[0].PriceType;
                self._auth.sendUserData(JSON.stringify(res.customerDetail[0]));
                self._auth.getUserData();
                self._auth.sendDefaultCountryCode("1");
                this._enrollSession.data.LoggedInUserData = res.customerDetail[0];
                this.getAutoOrder(res.customerDetail[0].CustomerID);
                localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
                localStorage.setItem('EnrollerData', JSON.stringify(res));
                this._enrollSession.data.IsLoggedIn = true;
                sessionStorage.setItem("IsSSOLogin",'true');
                if (this._sharedMethodService.validCustomerType()) {
                    this.getBPProduct(544, res.customerDetail[0].CustomerType);
                }
                if (res.customerDetail[0].CustomerType == 20 || res.customerDetail[0].CustomerType == 22) {
                    this.getImage(res.customerDetail[0].EnrollerID);
                } else {
                    this.getImage(res.customerDetail[0].CustomerID);
                }
                sessionStorage.getItem('balanceFieldPoint') !== null && sessionStorage.getItem('balanceFieldPoint') !== undefined ? (this._sharedMethodService.balanceField = sessionStorage.getItem('balanceFieldPoint')) : this._sharedMethodService.getPointAccount(res.customerDetail[0].CustomerID);
                sessionStorage.setItem('SSOToken', this.ssoToken);
                this.GetSuperAminoCustomerIDCheck(res.customerDetail[0].CustomerID);
            },
            error => {
                console.log(error , 'in error');
                this._router.navigate(['/homepage']);
            }
        )
        self._restService.GetCustomerBilling(customerId).subscribe((res) =>{
            self._auth.sendBillingInfo(JSON.stringify(res))
        }, error => {
            console.log("In error")
        })
    }

    GetSuperAminoCustomerIDCheck(customerId:any) 
    {
      const self = this;
      let req = (customerId ? customerId : 0);
      self._restService.GetSuperAminoCustomerIDCheck(req).subscribe(
        (res: any) => {
         sessionStorage.setItem("SuperAminoLimit", res[0].qty_allowed);
        },
        error => {
          console.log(error, 'in error');
        }
      )
    }

    onLogin(customerId, username, isToken) {
        const self = this;
        if (isToken == true) {
            var request = objectToParams({
                client_id: customerId,
                grant_type: "password",
                userName: username,
                scope: username == "AdminSSO" ? "AdminToken" : "SSOToken"
            });
        }
        else {
            var request = objectToParams({
                client_id: customerId,
                grant_type: "password",
                userName: username
            });
        }
        self._restService.Token(request).subscribe((result: any) => {
            if (result && result.access_token) {
                this._auth.sendToken(result)
                this.createCookie('Authtoken', result, this.cookieDomainUrl);
                this.GetCustomersDetails(result.CustomerID.toString());
                this._enrollSession.data.RetailOrderSummary = {};
                this._enrollSession.data.RetailOrderSummary.customerInfoData = {};
                this._enrollSession.data.RetailOrderSummary.shippingFormData = {};
                this._enrollSession.data.RetailEnroll = {};
                this._enrollSession.data.isShippedToAnother = false;
                sessionStorage.removeItem('temp2');
                sessionStorage.removeItem('temp1');
                this._enrollSession.data.IsLoggedIn = true;
                this.getImage(this.loginDetails.CustomerID);
                setTimeout(() => {
                this.cookieService.delete('access_token', '/');
                this.cookieService.delete('X-Auth', '/');
                this.cookieService.deleteAll('/');
                let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
                this._enrollSession.data.RetailOrderSummary =  FromShareLinkCart == "true" ? this._enrollSession.data.RetailOrderSummary : {};
                this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
                this._enrollSession.data.IsLoggedIn = false;
                this._enrollSession.data.LoggedInUserData = {};
                this._enrollSession.data.priceType = 0;
                sessionStorage.setItem('onUltLifestyleFlow', 'false');
                this._router.navigate(['/homepage']).then(() => {
                    if (window.localStorage && window.sessionStorage) {
                    window.localStorage.removeItem('user');
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    }
                    window.location.reload();
                });
                    localStorage.setItem("isExistingAutoship", "false");
                alert("Your session has been expired!!")
                }, 1800000);
                sessionStorage.getItem('balanceFieldPoint') !== null && sessionStorage.getItem('balanceFieldPoint') !== undefined ? (this._sharedMethodService.balanceField = sessionStorage.getItem('balanceFieldPoint')) : this._sharedMethodService.getPointAccount(this.loginDetails.CustomerID);
            }
            else {
            
            }
        },
            error => {
                console.log("error", error);
            });
    }
   
    getCookie(name) {
        var nameEq = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEq) === 0) {
                return c.substring(nameEq.length, c.length);
            }
        }
        return null;
    }

    createCookie(name, value, domainurl) {
        let days = value.expires_in ? value.expires_in : "";
        value = value.access_token;
        var domain = '';
        var domainParts, date, expires, host;
        if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 1000);
            expires = '; expires=' + date;
            //expires = "; expires=" + new Date(days);
        } else {
            expires = '';
        }

        host = location.host;
        if (host.split('.').length === 1) {
            document.cookie = name + '=' + value + expires + '; path=/';
        } else {
            // Remember the cookie on all subdomains.
            //
            // Start with trying to set cookie to the top domain.
            // (example: if user is on foo.com, try to set
            //  cookie to domain ".com")
            //
            // If the cookie will not be set, it means ".com"
            // is a top level domain and we need to
            // set the cookie to ".foo.com"
            domainParts = domainurl; //host.split('.');
            domain = domainParts;

            document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain;

            // check if cookie was successfuly set to the given domain
            // (otherwise it was a Top-Level Domain)
            if (this.getCookie(name) == null || this.getCookie(name) != value) {
                // append "." to current domain
                //domain = '.' + host;
                document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain;
            }
        }
    }

    createCustomerLoginForm(): FormGroup {
        return this._formBuilder.group({
          userName: ['', [Validators.required]],
          password: ['', [Validators.required]]
        })
      }
    
      continue() {
        var access_token=JSON.parse(localStorage.getItem('authtoken'));
        this._router.navigate(['/Subscribe']);
      }
    
      SignIn() {
        if (this.customerLoginForm.invalid) {
          this.validateAllFormFields(this.customerLoginForm);
          return;
        }
    
        const request = objectToParams({
          client_id:'exigo',
          grant_type:'password',
          userName: this.userInfo.LoginName,
          password: this.userInfo.Password,
          scope:'password'
      });
    
      if(localStorage.getItem('isLoggedIn') == 'true') {
        this._toastr.error('Error', 'User has already login');
        return;
      }

      this._restService.Token(request).subscribe(
          {
            next: (result: any) => {
              if (result && result.access_token) {
                try {
                  localStorage.setItem('isLoggedIn', 'true');
                  this.loginDetails = result;
                  this._auth.sendToken(result);
                  this.GetCustomersDetails(this.loginDetails.CustomerID.toString());
                  this._enrollSession.data.RetailOrderSummary.customerInfoData = {};
                  this._enrollSession.data.RetailOrderSummary.shippingFormData = {};
                  this._enrollSession.data.RetailEnroll = {};
                  this._enrollSession.data.isShippedToAnother = false;
                  let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
                  sessionStorage.removeItem('temp2');
                  sessionStorage.removeItem('temp1');
                  this._enrollSession.data.RetailOrderSummary = {};
                  this.continue();
                }
                catch (successEx) {
                  this._toastr.error('Error', 'Oops Something Went Wrong');
                  console.error('ex', successEx);
                }
              } else {
                this._toastr.error('Error', 'Authentication Failed');
              }
            }, error: (err) => {
              this._toastr.error(err.error.error_description);
            },
            complete: () => {
            }
          });
      }
    
      forgotPassword() {
        this._router.navigate(['/forgotPassword']);
      }
    
      validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
          } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
          }
        });
      }

      getImage(id)
      {
          this._enrollSession.customerImage =  API_URL +"/NonProfitEnrollment/GetCustomerImageData?customerId="+id || '../../../assets/images/avatars/profile_icon.png';
      }

    getAutoOrder(customerId) {
        this._restService.GetAutoOrders(customerId).subscribe((res: any) => {
            if (res.AutoOrderList.length > 0 && this.siteType != 'ultlifestyle') {
                localStorage.setItem("isExistingAutoship", "true");
                this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {}
                this._enrollSession.data.RetailOrderSummary.InitialOrders = [];
                let CustomAutoOrderList = [];
                res.AutoOrderList.forEach(item => {
                    if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                        CustomAutoOrderList.push(item);
                    }
                });
                var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                    return (item.AutoOrderID == minAutoOrderId);
                });
                localStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
                localStorage.setItem("AutoOrderDetails", JSON.stringify(CustomAutoOrderList[0]));
                this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1" : "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
                localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
                this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                this._router.navigate(['/AllProducts']);
            }
            else {
                this._router.navigate(['/AllProducts']);
            }
        }, error => {
            console.log("In error")
        })


    }

    getBPProduct(webCategoryId, customertype) {
        var listData;
    
        const request = {
          'EnrollmentType': '',
          'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
          'WebCategoryId': webCategoryId,
          'isUpgradeCustomer': false,
          'IsSubscriptionOrder': false,
          'PriceType': ''
        };
        this._restService.getProducts(request).subscribe((res: any) => {
          listData = res.Items;
          listData.forEach((item) => {
            this._sharedMethodService.AllBPProducts.push(item);
          });
          sessionStorage.setItem('AllBPProducts',JSON.stringify(listData));
        });
      }
}
