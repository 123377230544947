import { Injectable } from '@angular/core';
import { BASE_URL, API_URL, objectToParams, FAQ_API_URL } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  authKey:string = "e93dc086-e25e-4aa6-90b0-e0389d5733c3"

  constructor(private _http: HttpClient) { }
  
  Delete(resultUrl: string): Observable<HttpResponse<object>> {
    const url = API_URL + resultUrl;
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json-patch+json; charset=UTF-8',
        'Authorization':  "Bearer " +access_token
      })
    };

    return this._http.delete<HttpResponse<object>>(url, httpOptions).pipe();
  }


  Put(resultUrl: string, requestParameterData: any): Observable<HttpResponse<object>> {
    const url = API_URL + resultUrl;
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':  "Bearer " +access_token
      })
    };

    return this._http.put<HttpResponse<object>>(url, requestParameterData, httpOptions).pipe();
  }


  Get(resultUrl: string): Observable<HttpResponse<object>> {
    
    const url = API_URL + resultUrl;
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization':  "Bearer " +access_token
      })
    };

    return this._http.get<HttpResponse<object>>(url, httpOptions).pipe(tap());
  }
  GetFAQ(resultUrl: string): Observable<HttpResponse<object>> {
    const url = FAQ_API_URL + resultUrl;
    return this._http.get<HttpResponse<object>>(url).pipe(tap());
  }


  Post(resultUrl: string, requestParameterData: any, timeout?: any): Observable<HttpResponse<object>> {
    requestParameterData = objectToParams(requestParameterData);
    var access_token=JSON.parse(localStorage.getItem('authtoken'));
    const url = API_URL + resultUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization':  "Bearer " +access_token
      })
    };

    return this._http.post<HttpResponse<object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  PostFile(resultUrl: string, requestParameterData: any, timeout?: any): Observable<HttpResponse<object>> {
    const url = API_URL + resultUrl;  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  Token(request): Observable<HttpResponse<object>> {
    const url = BASE_URL + '/token';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<object>>(url, request, httpOptions).pipe(tap());
  }

  GetCustomersDetails(request: any): Observable<HttpResponse<object>> {
    const _url = '/RecurringOrders/GetCustomersDetails' + request;
    return this.Get(_url);
  }

  GetCustomers(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetCustomers?CustomerId=' + request;
    return this.Get(Requesturl);
  }

  GetCustomersByEmail(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetCustomersByEmail?EmailId=' + request;
    return this.Get(Requesturl);
  }

  getProducts(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetOrderItems';
    return this.Post(Requesturl, request);
  }

  GetVerifedAddressResponseV1(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/Comman/GetVerifedAddressResponsev1';
    return this.Post(Requesturl, request);
  }

  getMemberShipKit(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetPackItems';
    return this.Post(Requesturl, request);
  }

  GetCountryRegions(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetCountryRegions';
    return this.Post(Requesturl, request);
  }

  ShippingMethod(request: any): Observable<HttpResponse<object>> {
    const Requesturl = 'GetShipMethods' + request;
    return this.Get(Requesturl);
  }

  VerifyUsername(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/VerifyUsername' + request;
    return this.Get(Requesturl);
  }

  VerifyGiftCard(request: any): Observable<HttpResponse<object>> {
    const Requesturl = 'IsValidGiftCard' + request;
    return this.Get(Requesturl);
  }

  GetCustomerBilling(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/getcustomerbilling?customerid=' + request;
    return this.Get(Requesturl);
  }

  VerifyWebalias(request: any): Observable<HttpResponse<object>> {

    const Requesturl = '/NonProfitEnrollment/VerifyWebalias' + request;
    return this.Get(Requesturl);
  }

  SubmitApplication(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/SubmitApplication';
    return this.Post(Requesturl, request);
  }

  IsEmailRegistered(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/IsEmailAvailable' + request;
    return this.Post(Requesturl, '');
  }

  calculateRetailOrder(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/CalculateOrder';
    return this.Post(Requesturl, request);
  }

  GetCustomerSite(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/Comman/GetCustomerSite' + request;
    return this.Get(Requesturl);
  }

  GetItems(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetTestItems' + request;
    return this.Get(Requesturl);
  }
  ForgotPassword(request: any): Observable<HttpResponse<object>> {
    const _url = '/Auth/ForgotPassword';
    return this.Post(_url,request);
  }
  CreateAutoOrder(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/RecurringOrders/CreateAutoOrder';
    return this.Post(Requesturl, request);
  }
  GetAutoOrders(req: string) {
    var _url = '/RecurringOrders/GetAutoOrders?CustomerID=' + req;
    return this.Get(_url);
  }
  SilentLogin(request: any): Observable<HttpResponse<object>> {
      const Requesturl = '/NonProfitEnrollment/GetSilentLoginToken?customerId=' + request;
    return this.Get(Requesturl);
  }
  GetResetPasswordLink(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/SendResetPasswordLink' + request;
    return this.Get(Requesturl);
  }
  ValidateResetPasswordToken(request: any): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/ValidateResetPasswordToken' + request;
    return this.Get(Requesturl);
  }
  ResetPassword(request: any): Observable<HttpResponse<object>> {
    const _url = '/NonProfitEnrollment/ResetPassword';
    return this.Post(_url,request);
  }
  validateCustomer(request): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/ValidateCustomer' + request;
    return this.Get(Requesturl)
  }
  
  GetAllJudgMeProducts(): Observable<HttpResponse<object>> {
    const Requesturl = `/GetAllJudgMeProducts`
    return this.Get(Requesturl)
  }

  JudgeMeReviews(request, page): Observable<HttpResponse<object>> {
    const Requesturl = `/JudgeMeIndex?handle=${request}&page=${page}`
    return this.Get(Requesturl)
  }
  GetProductReview(request): Observable<HttpResponse<object>> {
    const Requesturl = '/GetProductReview?productId=' + request;
    return this.Get(Requesturl)
  }
  getshopStats(): Observable<HttpResponse<object>> {
    const Requesturl = '/NonProfitEnrollment/GetiShopStats';
    return this.Get(Requesturl)
  }
  CreateProductReview(request: any): Observable<HttpResponse<object>> {
    const _url = '/CreateProductReview';
    return this.Post(_url,request);
  }
  GetPointAccount(request:any):Observable<HttpResponse<object>>{
    const _url = '/Comman/GetPointAccount';
    return this.Post(_url,request);
  }
  ValidatePhoneNumber(phoneNumber:string=""):Observable<HttpResponse<object>>{
    const _url = `/Comman/ValidatePhoneNumber?phoneNumber=${phoneNumber}`;
    return this.Get(_url);
  }
  getCollectionCategories(request:any):Observable<HttpResponse<object>>{
    const Requesturl = '/NonProfitEnrollment/GetCollectionCategories';
    return this.Post(Requesturl,request);
  }
    getBlogs(request: any): Observable<HttpResponse<object>> {
        const _url = `/JudgeMe/GetBlogs?postId=${request}`;
        return this.Get(_url);
    }
    GetPages(request: any): Observable<HttpResponse<object>> {
        const _url = `/GetPages?pageId=${request}`;
        return this.Get(_url);
    }
    GetIshopBannerContent(){
      const Requesturl = '/NonProfitEnrollment/getIshopContent';
      return this.Get(Requesturl);
    }
    CreateCustomer(request:any):Observable<HttpResponse<object>>{
      const Requesturl = '/NonProfitEnrollment/CreateCustomer';
      return this.Post(Requesturl,request);
    }
    getFaqwithCatList(): Observable<HttpResponse<object>> {
      const Requesturl = `/Public/GetFaqs/${this.authKey}`; 
      return this.GetFAQ(Requesturl);
    }
    getTopFaqList(): Observable<HttpResponse<object>> {
      const Requesturl = `/Public/GetTopFaqs/${this.authKey}`;
      return this.GetFAQ(Requesturl);
    }
    createCustomerExtended(request){
        const RequestUrl = '/NonProfitEnrollment/CreateCustomerExtended';
        return this.Post(RequestUrl,request)
    }
    GetSuperAminoCustomerIDCheck(request: any): Observable<HttpResponse<object>> {
      const Requesturl = '/NonProfitEnrollment/GetSuperAminoCustomerIDCheck?CustomerId=' + request;
      return this.Get(Requesturl);
    }
    GetSuperAminoAddressCheck(request){
      const RequestUrl = '/NonProfitEnrollment/GetSuperAminoAddressCheck';
      return this.Post(RequestUrl,request)
    }
}
