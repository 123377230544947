<app-header></app-header><br>
<section id="newHomePage">
	<div class="smallScreenPadding display-flex-row">
		<div class="width-50 text-center pt-4">
			<a routerLink="/Ultlifestyle" class="main-btn subscribe-btn mt-40"><div>Lifestyle Subscription&nbsp;&nbsp;&nbsp;</div>
				<img class=""
			   src="../../../assets/icons/WhiteLineArrow.png" width="70"
			   alt=""></a><br>
			<a routerLink="/AllProducts" class="main-btn products-btn mt-40">
				<div>Shop All Products&nbsp;&nbsp;&nbsp;&nbsp;</div>
				<img class=""
				src="../../../assets/icons/LineArrow.png" width="70"
				alt=""></a>
		</div>
		<div class="w-50">
			<img class=""
			src="../../../assets/images/home_img/Spoon Image V2.png"
			alt="">
		</div>
	</div>
	<div class="smallScreenPadding" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod()}">
		<div class="RealPeople">
			<span class="g-color">Real People, Real Results!</span>
		</div>
		<div class="RealPeopleText">
			<span>These are just a few of the many stories that keep us inspired and motivated. </span>
		</div>
		<div class="mt-50 display-flex">
			<div class="cursor-pointer color-zoom" (click)="prev()">
				<img src="../../../assets/icons/left-nav.png" alt="Previous" class="nav-arrow left-arrow">
			</div>
			<ngx-slick-carousel class="container carousel customCarousel" #slickModal="slick-carousel" [config]="slideConfig">
				<div ngxSlickItem *ngFor="let slide of banners" class="slide">
					<div class="align-items-center justify-content-center d-flex flex-column"
						style="width: 100%;height: auto;">
						<img class="customCarouselImg imageSlickSize" [src]="slide.url" alt="unable to load please try again">
					</div>
				</div>
			</ngx-slick-carousel>
			<div class="cursor-pointer color-zoom" (click)="next()">
				<img src="../../../assets/icons/right-nav.png" alt="Next" class="nav-arrow right-arrow">
			</div>
		</div>
	</div>
	<div class="container smallScreenPadding" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod(), 'mt-20': _enrollSession.mobileViewMethod()}">
		<div class="image-container">
			<img class=""
			src="../../../assets/images/home_img/NowHSA_FSA.png"
			alt="">
			<a href="https://app.truemed.com/qualify/tm_qual_x35w0cgiul" target="_blank" class="clickable-overlay" title="Submit Now"></a>
		</div>
	</div>
	<div class="smallScreenPadding" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod(), 'mt-20': _enrollSession.mobileViewMethod()}">
		<div class="PuirumNewesttext">
			<span class="color-pink">Introducing Purium’s Newest Products</span>
		</div>
		<div class="display-flex" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod()}">
			<div class="cursor-pointer color-zoom" (click)="prevSlide()">
				<img src="../../../assets/icons/left-nav.png" alt="Previous" class="nav-arrow left-arrow">
			</div>
			<ngx-slick-carousel class="container sliding1 color-grey" [ngClass]="{'width-80': _enrollSession.mobileViewMethod()}" #slickModal1="slick-carousel" [config]="slideConfig">
				<div ngxSlickItem *ngFor="let slides of PuriumNewestBanner">
					<img class="" [ngStyle]="getTextBannerButton()" [src]="slides.banner" alt="unable to load please try again">
				</div>
			</ngx-slick-carousel>
			<div class="cursor-pointer color-zoom" (click)="nextSlide()">
				<img src="../../../assets/icons/right-nav.png" alt="Next" class="nav-arrow right-arrow">
			</div>
		</div>
		<div class="container" [ngClass]="{'width-80': _enrollSession.mobileViewMethod()}">
			<div class="absolute">
				<a [routerLink]="currentLink" class="main-btn mt-40 bannerButton collection-btn1">
					<div id="textchange">Shop All Pineal Clear Products&nbsp;&nbsp;</div>
					<img id="imageBannerArrow" class="desktopview"
					src="../../../assets/icons/BlueLineArrow.png" width="70"
					alt="">
					<img id="imageBannerArrowMobile" class="mobileview margin-l"
					src="../../../assets/icons/BlueLineArrow.png" width="20"
					alt="">
				</a>
			</div>
		</div>
	</div>
	<div class="container m-t-mobile smallScreenPadding">
		<div class="PurePremiumtext">
			<span class="color-brown">Pure + Premium  =</span>&nbsp;<span class="color-green">Purium</span>
		</div>
		<!-- <div class="col-lg-8 col-md-12">
			<div class="iframeVideo">
				<iframe
				src="https://player.vimeo.com/video/890102411?autoplay=1&loop=1&muted=1" frameborder="0"
				style="position:absolute;top:0;left:0;width:100%;height:100%;"
				title="Exposing the Fake Food Conspiracy" allowfullscreen="" data-resize-to-parent="true" allow="clipboard-write"></iframe>
		</div>
			<script src="https://player.vimeo.com/api/player.js"></script>
			<script src="https://v0.wordpress.com/js/next/videopress-iframe.js?m=1674852142"></script>
		</div> -->
	</div>
	<div class="container mt-50 smallScreenPadding">
		<section class="room-gallery-cta selectors background-img">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10">
						<div class="cta-text text-center spoon_text">
							The quality of food you eat is equal to the quality of life you live.
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="container mt-50 smallScreenPadding">
		<div class="  wp-block-group alignfull is-layout-constrained wp-block-group-is-layout-constrained ">
			<div class="wp-block-group__inner-container">
				<div
					class="row mb-0 sqrBracket wp-block-columns">
					<div class="goodForHelath col-lg-6 col-md-10">
						<p class="puriumwhytext wp-block-heading has-text-align-center"><span>Why Purium</span></p>
						<div class="row is-layout-flex wp-container-23 wp-block-columns-is-layout-flex mt-30">
							<div
								class="col-lg-4 col-md-12 wp-block-column is-layout-flow wp-block-column-is-layout-flow align-items-center justify-content-center d-flex">
								<div class="wp-block-image">
									<figure class="aligncenter size-large is-resized">
										<img data-attachment-id="1420"
											data-permalink="https://puriumbusiness.com/home-2/g-generating-low/"
											data-orig-file="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?fit=1080%2C1080&amp;ssl=1"
											data-orig-size="1080,1080" data-comments-opened="1"
											data-image-meta="{&quot;aperture&quot;:&quot;0&quot;,&quot;credit&quot;:&quot;&quot;,&quot;camera&quot;:&quot;&quot;,&quot;caption&quot;:&quot;&quot;,&quot;created_timestamp&quot;:&quot;0&quot;,&quot;copyright&quot;:&quot;&quot;,&quot;focal_length&quot;:&quot;0&quot;,&quot;iso&quot;:&quot;0&quot;,&quot;shutter_speed&quot;:&quot;0&quot;,&quot;title&quot;:&quot;&quot;,&quot;orientation&quot;:&quot;0&quot;}"
											data-image-title="g Generating Low" data-image-description="" data-image-caption=""
											data-medium-file="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?fit=300%2C300&amp;ssl=1"
											data-large-file="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?fit=640%2C640&amp;ssl=1"
											decoding="async" loading="lazy" width="640" height="640"
											src="{{IMAGE_URL}}/assets/images/home_img/g-Generating-Low.jpg" alt="" class="wp-image-1420"
											style="object-fit:cover;width:204px;height:204px"
											srcset="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=1024%2C1024&amp;ssl=1 1024w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=300%2C300&amp;ssl=1 300w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=150%2C150&amp;ssl=1 150w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=768%2C768&amp;ssl=1 768w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=800%2C800&amp;ssl=1 800w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=400%2C400&amp;ssl=1 400w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=200%2C200&amp;ssl=1 200w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?resize=120%2C120&amp;ssl=1 120w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Generating-Low.png?w=1080&amp;ssl=1 1080w"
											sizes="(max-width: 640px) 100vw, 640px" data-recalc-dims="1">
									</figure>
								</div>
							</div>
							<div
								class="col-lg-4 col-md-12  wp-block-column is-layout-flow wp-block-column-is-layout-flow align-items-center justify-content-center d-flex">
								<div class="wp-block-image">
									<figure class="aligncenter size-large is-resized">
										<img data-attachment-id="1416" loading="lazy" width="640" height="640"
											src="{{IMAGE_URL}}/assets/images/home_img/g-Composta.jpg" alt="" class="wp-image-1416"
											style="object-fit:cover;width:204px;height:204px"
											srcset="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=1024%2C1024&amp;ssl=1 1024w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=300%2C300&amp;ssl=1 300w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=150%2C150&amp;ssl=1 150w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=768%2C768&amp;ssl=1 768w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=800%2C800&amp;ssl=1 800w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=400%2C400&amp;ssl=1 400w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=200%2C200&amp;ssl=1 200w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?resize=120%2C120&amp;ssl=1 120w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Compostable- Packaging.png?w=1080&amp;ssl=1 1080w"
											sizes="(max-width: 640px) 100vw, 640px" data-recalc-dims="1" />
									</figure>
								</div>
							</div>
							<div
								class="col-lg-4 col-md-12  wp-block-column is-layout-flow wp-block-column-is-layout-flow align-items-center justify-content-center d-flex">
								<div class="wp-block-image">
									<figure class="aligncenter size-large is-resized"><img data-attachment-id="1417"
											data-permalink="https://puriumbusiness.com/home-2/g-eco-friendly-shipping/"
											data-orig-file="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?fit=1080%2C1080&amp;ssl=1"
											data-orig-size="1080,1080" data-comments-opened="1"
											data-image-meta="{&quot;aperture&quot;:&quot;0&quot;,&quot;credit&quot;:&quot;&quot;,&quot;camera&quot;:&quot;&quot;,&quot;caption&quot;:&quot;&quot;,&quot;created_timestamp&quot;:&quot;0&quot;,&quot;copyright&quot;:&quot;&quot;,&quot;focal_length&quot;:&quot;0&quot;,&quot;iso&quot;:&quot;0&quot;,&quot;shutter_speed&quot;:&quot;0&quot;,&quot;title&quot;:&quot;&quot;,&quot;orientation&quot;:&quot;0&quot;}"
											data-image-title="g Eco-Friendly Shipping" data-image-description="" data-image-caption=""
											data-medium-file="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?fit=300%2C300&amp;ssl=1"
											data-large-file="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?fit=640%2C640&amp;ssl=1"
											decoding="async" loading="lazy" width="640" height="640"
											src="{{IMAGE_URL}}/assets/images/home_img/g-Eco-Friendly-Shipping.jpg" alt=""
											class="wp-image-1417" style="width:204px;height:204px"
											srcset="https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=1024%2C1024&amp;ssl=1 1024w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=300%2C300&amp;ssl=1 300w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=150%2C150&amp;ssl=1 150w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=768%2C768&amp;ssl=1 768w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=800%2C800&amp;ssl=1 800w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=400%2C400&amp;ssl=1 400w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=200%2C200&amp;ssl=1 200w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?resize=120%2C120&amp;ssl=1 120w, https://i0.wp.com/puriumbusiness.com/wp-content/uploads/2023/08/g-Eco-Friendly-Shipping.png?w=1080&amp;ssl=1 1080w"
											sizes="(max-width: 640px) 100vw, 640px" data-recalc-dims="1">
									</figure>
								</div>
							</div>
							<p class="goodForEarth wp-block-heading has-text-align-center"><span>Good for the earth,<br *ngIf="_enrollSession.mobileViewMethod()"> Good for your body</span></p>
							<div class="text-center padding-tab">
								<a routerLink="/Ultlifestyle" class="main-btn subscribe1-btn mt-20"><div>Lifestyle Subscription&nbsp;&nbsp;&nbsp;</div>
									<img class=""
								   src="../../../assets/icons/WhiteLineArrow.png" width="70"
								   alt=""></a>
								<a routerLink="/AllProducts" class="main-btn products1-btn mt-20">
									<div>Shop All Products&nbsp;&nbsp;&nbsp;&nbsp;</div>
									<img class=""
									src="../../../assets/icons/LineArrow.png" width="70"
									alt=""></a>
							</div>
						</div>
					</div>
				
					<div class="col-lg-6 col-md-10">
						<div class="margin-mobile">
							<img src="../../../assets/images/home_img/PuriumHomepageGoodForHealth.png">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="height:50px" aria-hidden="true" class="wp-block-spacer smallScreenPadding"></div>
	<div class="container m-t-mobile mt-50 smallScreenPadding">
	</div>
	<div class="container mt-50 smallScreenPadding">
		<div class="clubMemberSwagHeading">
			Club Member Swag
		</div>
		<div class="clubMemberText">
			The health benefits are rewarding and so is Purium’s Lifestyle Club!
		</div>
		<div class="row">
			<div class="col-lg-6 col-md-12 col-sm-12">
				<div class="clubMemberImage1">
					<img src="../../../assets/images/home_img/ClubMemberLogo.png">
				</div>
				<div class="clubMemberImage2">
					<img src="../../../assets/images/home_img/tshirtMockupClub.png">
				</div>
			</div>
			<div class="container textpointclub col-lg-6 col-md-12 col-sm-12">
				<div>
					<p class="textheadingclub">Free Gift with Month 1</p>
					<ul>
						<li class="fs-18">
							The “You’re Worth It” bracelet is a <br>special reminder to be kind to <br>yourself, follow your tracker and<br>
							discover your best self!
						</li>
					</ul>
				</div>
				<div class="mt-50">
					<p class="textheadingclub">Free Gift with Month 3’s Pack</p>
					<ul>
						<li class="fs-18">
							Be reminded of your accomplishment<br> every time you wear this exclusive,<br> organic cotton T-shirt!
						</li>
					</ul>
				</div>
				<div class="pt-5 pr-10">
					<a routerLink="/Ultlifestyle" class="main-btn subscribe-btn2 mt-40"><div>Lifestyle Subscription&nbsp;&nbsp;&nbsp;</div>
						<img class=""
					   src="../../../assets/icons/WhiteLineArrow.png" width="70"
					   alt=""></a>
				</div>
			</div>
		</div>
	</div>
	<div class="container mt-50 m-t-mobile smallScreenPadding">
		<div class="ownersText">
			Co-owners with a Passion
		</div>
		<div>
			<img src="../../../assets/images/home_img/PuriumMeetDayPerson.png">
		</div>
		<div class="MeetOwnersText">
			Meet Purium’s Owners, Amy Venner & Dave Sandoval 
		</div>
		<div class="button-meet-dave">
			<a href="https://blog.puriumcorp.com/2021/02/08/meet-purium-co-founder-amy-venner/" class="main-btn meet-dave-btn mt-20" target="_blank"><div>Meet Amy&nbsp;&nbsp;&nbsp;</div>
				<img class="ml-15"
				src="../../../assets/icons/WhiteLineArrow.png" width="70"
				alt=""></a>
			<a href="https://blog.puriumcorp.com/2021/02/08/meet-purium-co-founder-dave-sandoval/" class="main-btn meet-dave-btn mt-20" target="_blank"><div>Meet Dave&nbsp;&nbsp;&nbsp;</div>
				<img class="ml-15"
				src="../../../assets/icons/WhiteLineArrow.png" width="70"
				alt=""></a>
		</div>
	</div>
	<div class="container mt-50 m-t-mobile smallScreenPadding">
		<div class="ownersText">
			Help us celebrate 20 years of superfoods, <br *ngIf="!_enrollSession.mobileViewMethod()">community, eco-friendly practices and financial empowerment!
		</div>
		<div class="meetDaveInfo">
			<img class="img-width" src="../../../assets/images/home_img/MarryFleming.png" alt="Marry Fleming">
			<img class="img-width" src="../../../assets/images/home_img/SoniaMagruder.png" alt="Sonia Magruder">
			<img class="img-width" src="../../../assets/images/home_img/TracieFujikane.png" alt="Tracie Fujikane">
		</div>
		<div class="button-meet-dave">
			<a routerLink="/amy-and-dave" class="main-btn meet-dave-info-btn mt-20"><div>Submit Your Own Message&nbsp;&nbsp;&nbsp;&nbsp;</div>
				<img class="ml-meet-dave"
				src="../../../assets/icons/LineArrow.png" width="70"
				alt=""></a>
		</div>
	</div>
</section>
<div>
	<app-footercomponent></app-footercomponent>
</div>